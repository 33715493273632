import React, { Component, Fragment } from "react";
//import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import { clientConnect } from "../../client";
//import Moment from "react-moment";

export class NewsList extends Component {
  constructor(props) {
    super();
    this.state = {
      news: [],
      loadingNews: true,
    };
  }

  componentDidMount() {
    if (!this.props.content) {
      this.populateNewsData();
    } else {
      this.populateNewsData(this.props.categoryUrl);
    }
  }

  render() {
    var newsTails = [];

    if (this.state.news.length > 1 && this.state.news.length >= 4) {
      for (let i = 1; i < 4; i++) {
        newsTails.push(
          <div className="col-md-4 xs-text-center">
            <p>
              {this.state.news[i].hasImage && (
                <a href={`/${this.props.baseUrl.url}/news/${this.state.news[i].url}`}>
                  {this.state.news[i].imageAssetMode === 1 && (
                    <img
                      src={`https://cdn.bluebirdcms.net/imageasset/get/${this.state.news[i].imageAssetHash}`}
                      alt={this.state.news[i].title}
                      className="img-fluid"
                      layout="responsive"
                    />
                  )}
                  {this.state.news[i].imageAssetMode === 3 && (
                    <img
                      src={`https://cdn3.bluebirdcms.net/images/${process.env.siteId}/${this.state.news[i].imageAssetFileName}`}
                      alt={this.state.news[i].title}
                      className="img-fluid"
                      loading="lazy"
                    />
                  )}
                  {this.state.news[i].imageAssetMode === 4 && (
                    <img
                      src={`https://cdn4.bluebirdcms.net/images/${process.env.siteId}/${this.state.news[i].imageAssetFileName}`}
                      alt={this.state.news[i].title}
                      className="img-fluid"
                      loading="lazy"
                    />
                  )}
                </a>
              )}
            </p>
            <h3>
              <a href={`/${this.props.baseUrl.url}/news/${this.state.news[i].url}`}>{this.state.news[i].title}</a>
            </h3>
            <h5 className="news-category">
              <a href={`/${this.props.baseUrl.url}/category/${this.state.news[i].categoryUrl}`}>
                <i className="fa fa-sitemap"></i>
                <span className="pl-2">{this.state.news[i].categoryName}</span>
              </a>
            </h5>
            {ReactHtmlParser(this.state.news[i].abstract)}
            <p>
              <a href={`/${this.props.baseUrl.url}/news/${this.state.news[i].url}`} className="btn btn-default">
                read more...
              </a>
            </p>
          </div>
        );
      }
    }

    if (this.state.news.length > 1 && this.state.news.length < 4) {
      for (let i = 1; i < this.state.news.length; i++) {
        newsTails.push(
          <div className="col-md-4">
            <p>
              {this.state.news[i].hasImage && (
                <a href={`/${this.props.baseUrl.url}/news/${this.state.news[i].url}`}>
                  {this.state.news[i].imageAssetMode === 1 && (
                    <img
                      src={`https://cdn.bluebirdcms.net/imageasset/get/${this.state.news[i].imageAssetHash}`}
                      alt={this.state.news[i].title}
                      className="img-fluid"
                      layout="responsive"
                    />
                  )}
                  {this.state.news[i].imageAssetMode === 3 && (
                    <img
                      src={`https://cdn3.bluebirdcms.net/images/${process.env.siteId}/${this.state.news[i].imageAssetFileName}`}
                      alt={this.state.news[i].title}
                      className="img-fluid"
                      loading="lazy"
                    />
                  )}
                  {this.state.news[i].imageAssetMode === 4 && (
                    <img
                      src={`https://cdn4.bluebirdcms.net/images/${process.env.siteId}/${this.state.news[i].imageAssetFileName}`}
                      alt={this.state.news[i].title}
                      className="img-fluid"
                      loading="lazy"
                    />
                  )}
                </a>
              )}
            </p>
            <h3>
              <a href={`/${this.props.baseUrl.url}/news/${this.state.news[i].url}`}>{this.state.news[i].title}</a>
            </h3>
            <h5 className="news-category">
              <a href={`/${this.props.baseUrl.url}/category/${this.state.news[i].categoryUrl}`}>
                <i className="fa fa-sitemap"></i>
                <span className="pl-2">{this.state.news[i].categoryName}</span>
              </a>
            </h5>
            {ReactHtmlParser(this.state.news[i].abstract)}
            <p>
              <a href={`/${this.props.baseUrl.url}/news/${this.state.news[i].url}`} className="btn btn-default">
                read more...
              </a>
            </p>
          </div>
        );
      }
    }

    var newsList = [];

    if (this.state.news.length >= 5) {
      for (let j = 4; j < this.state.news.length; j++) {
        newsList.push(
          <div className="row justify-content-md-center">
            <div className="col-md-10" style={{ borderBottom: "1px solid #051E38", paddingTop: "10px", paddingBottom: "10px" }} key={j}>
              <div className="row">
                <div className="col-10 col-md-11">
                  <h3>
                    <a href={`/${this.props.baseUrl.url}/news/${this.state.news[j].url}`}>{this.state.news[j].title}</a>
                  </h3>
                </div>
                <div className="col-2 col-md-1">
                  <i className="fa fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <Fragment>
        <div style={{ marginTop: "20px", marginBottom: "20px", paddingTop: "30px" }}>
          <div className="row">
            <div className="col-12 xs-text-center">
              <div className="h1" style={{ textAlign: "center" }}>
                {this.props.content.title}
              </div>
              <div className="title-page-divider">&nbsp;</div>
            </div>
          </div>

          {!this.state.loadingEvents ? (
            <Fragment>
              {this.state.news.length > 0 ? (
                <Fragment>
                  <div className="row">
                    <div className="col-md-6">
                      {this.state.news[0].hasImage && (
                        <p className="xs-text-center">
                          <a href={`/${this.props.baseUrl.url}/news/${this.state.news[0].url}`}>
                            {this.state.news[0].imageAssetMode === 1 && (
                              <img
                                src={`https://cdn.bluebirdcms.net/imageasset/get/${this.state.news[0].imageAssetHash}`}
                                alt={this.state.news[0].title}
                                className="img-fluid"
                                layout="responsive"
                              />
                            )}
                            {this.state.news[0].imageAssetMode === 3 && (
                              <img
                                src={`https://cdn3.bluebirdcms.net/images/${process.env.siteId}/${this.state.news[0].imageAssetFileName}`}
                                alt={this.state.news[0].title}
                                className="img-fluid"
                                loading="lazy"
                              />
                            )}
                            {this.state.news[0].imageAssetMode === 4 && (
                              <img
                                src={`https://cdn4.bluebirdcms.net/images/${process.env.siteId}/${this.state.news[0].imageAssetFileName}`}
                                alt={this.state.news[0].title}
                                className="img-fluid"
                                loading="lazy"
                              />
                            )}
                          </a>
                        </p>
                      )}
                    </div>
                    <div className="col-md-6 xs-text-center">
                      <h3>
                        <a href={`/${this.props.baseUrl.url}/news/${this.state.news[0].url}`}>{this.state.news[0].title}</a>
                      </h3>
                      <h5 className="news-category">
                        <a href={`/${this.props.baseUrl.url}/category/${this.state.news[0].categoryUrl}`}>
                          <i className="fa fa-sitemap"></i>
                          <span className="pl-2">{this.state.news[0].categoryName}</span>
                        </a>
                      </h5>
                      {ReactHtmlParser(this.state.news[0].abstract)}
                      <p>
                        <a href={`/${this.props.baseUrl.url}/news/${this.state.news[0].url}`} className="btn btn-default">
                          read more...
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className="row">{newsTails}</div>

                  {newsList}
                </Fragment>
              ) : (
                <Fragment>
                  <p className="text-center" style={{ fontSize: "52px" }}>{`{...}`}</p>
                  <p className="text-center">no news available</p>
                </Fragment>
              )}

              {this.props.categoryUrl !== undefined && (
                <>
                  <hr />
                  <div style={{ paddingTop: "15px", paddingBottom: "15px", margin: "20px" }} className="text-center">
                    <a href={`/${this.props.baseUrl.url}`}>go back to all categories list</a>
                  </div>
                </>
              )}
            </Fragment>
          ) : (
            <p className="text-center">Loading...</p>
          )}
        </div>
      </Fragment>
    );
  }

  async populateNewsData(url) {
    if (url) {
      this.setState({ news: await clientConnect("api/news/categorylist", url), loadingNews: false });
    } else {
      this.setState({ news: await clientConnect("api/news/list", url), loadingNews: false });
    }
  }
}

export default NewsList;
