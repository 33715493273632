import React, { Component } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { Container, Navbar, NavbarBrand } from "reactstrap";
//import { accessToken, siteId, apiUrl } from "../../config";

import { clientConnect } from "../../client";

import "./SlideMenu.css";
import "./NavMenu.css";

export class MobileMenu extends Component {
  static displayName = MobileMenu.name;

  showSettings(event) {
    event.preventDefault();
  }

  constructor(props) {
    super();

    this.state = {
      navMenus: [],
      slideMenus: [],
      loadingNav: true,
      loadingSlide: true,
      menuOpen: false,
      color: "transparent",
    };
  }

  listenScrollEvent = (e) => {
    if (window.scrollY > 100) {
      this.setState({ color: "#262626" });
    } else {
      this.setState({ color: "transparent" });
    }
  };

  sendData = () => {
    this.props.slideMenuCallback(this.state.menuOpen);
  };

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen }, this.sendData);
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu() {
    this.setState({ menuOpen: false });
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }

  componentDidMount() {
    this.populateSlideMenuData();
    this.populateMainMenuData();
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  renderLink(item) {
    switch (item.miModule) {
      case "text":
        return (
          <a key={item.id} id={item.miUrl} className="bm-item menu-item" href={`/${item.miUrl}`}>
            {item.miTitle}
          </a>
        );
      default:
        return "foo";
    }
  }

  static renderMenu(navMenus) {
    return (
      <React.Fragment>
        {navMenus.length > 0 && (
          <React.Fragment>
            {navMenus.menuList[0].map((menuItem, i) => (
              <div key={i}>
                {menuItem.miModule.includes("section") ? (
                  <ScrollLink to={menuItem.url} spy={true} smooth={true} duration={500} offset={-180} className="bm-item menu-item">
                    {menuItem.title}
                  </ScrollLink>
                ) : (
                  <a key={i} id={menuItem.url} className="bm-item menu-item" href={menuItem.url}>
                    {menuItem.title}
                  </a>
                )}
              </div>
            ))}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  static renderSecondaryMenu(slideMenus) {
    return (
      <React.Fragment>
        {slideMenus !== undefined &&
          slideMenus.menuList[0].menu.length > 0 &&
          slideMenus.menuList[0].menu.map((slideMenuItem, i) => {
            switch (slideMenuItem.module) {
              case "text":
                return (
                  <a key={i} id={slideMenuItem.url} className="bm-item menu-item" href={`/${slideMenuItem.url}`}>
                    {slideMenuItem.title}
                  </a>
                );
              case "link":
                return (
                  <a key={i} id={slideMenuItem.url} className="bm-item menu-item" href={`/${slideMenuItem.url}`}>
                    {slideMenuItem.title}
                  </a>
                );
              default:
                return (
                  <a key={i} id={slideMenuItem.url} className="bm-item menu-item" href={`/${slideMenuItem.url}`}>
                    {slideMenuItem.title}
                  </a>
                );
            }
          })}
      </React.Fragment>
    );
  }

  render() {
    const mainMenu = !this.state.loadingNav ? this.state.navMenus : null;

    let secondaryMenu = this.state.loadingSlide ? <p className="text-center">Loading...</p> : MobileMenu.renderSecondaryMenu(this.state.slideMenus);

    //const { mainMenu } = this.state.mainMenus;

    return (
      <React.Fragment>
        <Menu
          right
          customBurgerIcon={false}
          customCrossIcon={<i className="fa fa-times"></i>}
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          width={"350px"}
        >
          {/* {!this.state.loadingNav &&
            mainMenu !== null &&
            mainMenu.menuList.map((menuItem, i) => (
              <div key={i}>
                {menuItem.miModule.includes("section") ? (
                  <React.Fragment>
                    {this.props.path == "/" ? (
                      <ScrollLink
                        onClick={() => this.toggleMenu()}
                        to={menuItem.miUrl}
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-180}
                        className="bm-item menu-item"
                      >
                        {menuItem.miTitle}
                      </ScrollLink>
                    ) : (
                      <a
                        key={i}
                        id={menuItem.miUrl}
                        className="bm-item menu-item"
                        href={`/#${menuItem.miUrl}`}
                      >
                        {menuItem.miTitle}
                      </a>
                    )}
                  </React.Fragment>
                ) : (
                  <a
                    key={i}
                    id={menuItem.miUrl}
                    className="bm-item menu-item"
                    href={menuItem.miUrl}
                  >
                    {menuItem.miTitle}
                  </a>
                )}
              </div>
              <hr />
            ))} */}

          {secondaryMenu}

          <hr />

          <a className="bm-item menu-item menu-item-small text-center" href="/members/signup">
            <i className="fa fa-user"></i>&nbsp;&nbsp;Become a member
          </a>

          <div className="social-buttons social-buttons-mobile mt-4">
            <a href="https://www.facebook.com/bepolux" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook fa-2x"></i>
            </a>
            <a href="https://www.linkedin.com/company/bepolux-the-belgian-luxembourg-polish-chamber-of-commerce" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin fa-2x"></i>
            </a>
          </div>
        </Menu>

        <header>
          <Navbar
            className="navbar-expand-sm navbar-toggleable-sm ng-white mb-3"
            dark
            fixed="top"
            style={{
              backgroundColor: global.window.scrollY > 100 ? "#fff" : "transparent",
            }}
          >
            <Container>
              <NavbarBrand href="/">
                <img src={process.env.PUBLIC_URL + "/images/logo_65years_transp.png"} alt="BEPOLUX" style={{ width: "200px" }} />
              </NavbarBrand>

              <div className="col col-md-2 col-xs-2 col-sm-2 text-right">
                <button onClick={() => this.toggleMenu()} className="menu-button">
                  <i className="fa fa-bars"></i>
                </button>
              </div>
            </Container>
          </Navbar>
        </header>
      </React.Fragment>
    );
  }

  async populateMainMenuData() {
    /*const response = await fetch('api/menu/main');
        const data = await response.json();
        this.setState({ mainMenus: data, loading: false });*/
    this.setState({
      navMenus: await clientConnect("api/menu/get", null),
      loadingNav: false,
    });
  }

  async populateSlideMenuData() {
    /*const response = await fetch('api/menu/secondary');
        const data = await response.json();
        this.setState({ secondaryMenus: data, loadingSecondary: false });*/
    this.setState({
      slideMenus: await clientConnect("api/menu/get", "1658d64d-80e3-4c4b-b6e7-304d35abb6f7"),
      loadingSlide: false,
    });
  }
}
